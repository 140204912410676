import React from "react"
import { graphql, Link } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import ButtonLink from "../components/ButtonLink"
import SectionTitle from "../components/SectionTitle"
import NewsletterSignUp from "../components/NewsletterSignup"

const BlankTemplate = () => {
  return (
    <Layout>
      <SEO />
      <Hero></Hero>
      {/* <NewsletterSignUp>This needs to Change</NewsletterSignUp> */}
    </Layout>
  )
}

export default BlankTemplate
